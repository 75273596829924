import React, {useEffect, useState} from 'react';
import Cards from 'react-credit-cards-2';
import NumberFormat from "react-number-format";
import visa from "../media/svg/visa.svg"
import master from "../media/svg/mastercard.svg"
import america from "../media/svg/american-express.svg"
import {toast} from "react-toastify";
import {globalMaskReal, maskRealNu, maskRealToView} from "../helpers/Functions";
import Select from 'react-select'
import ErrorMessage from "./ErrorMessage";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {Controller, useForm} from "react-hook-form";
import {FaArrowRight} from "react-icons/fa";
import * as Sentry from "@sentry/react";

const PaymentComponent = (props) => {
    const [state, setState] = useState({
        number: '',
        expiry: '',
        cvc: '',
        name: '',
        focus: '',
    });
    const [parcels, setParcels] = useState(undefined);
    const [loading, setLoading] = useState(false);


    const schema = yup.object().shape({
        number: yup
            .string()
            .min(16, "Número inválido")
            .required('Número do cartão é obrigatório'),
        expiry: yup
            .string()
            .required('Data de validade é obrigatória'),
        cvc: yup
            .string()
            .required('Código de segurança é obrigatório'),
        name: yup
            .string()

    });

    const { register, handleSubmit, control, watch, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            number: '',
            expiry: '',
            cvc: '',
            name: ''
        }
    });
    const configureIugu = () => {
        /* eslint-disable */
        Iugu.setAccountID("F611E76ADD2B46F79880273AF3F51638");
        // eslint-disable-next-line no-use-before-define
        Iugu.setTestMode(false);
        /* eslint-enable */
    }


    const generateToken = (state) => {
        setLoading(true);

         var primeiroNome = state.name.split(' ')[0];
         var sobreNome = state.name.slice(primeiroNome.length + 1);

         if(!sobreNome) {
             setLoading(false);
             toast.info("Informe seu nome Completo");
             return 0;
         }
        /* eslint-disable */

         let cc = Iugu.CreditCard(state.number,
             state.expiry.toString()[0]+state.expiry.toString()[1],
             state.expiry.toString()[2]+state.expiry.toString()[3],
             primeiroNome,
             sobreNome,
             state.cvc);

         if(!cc.valid()) {
             setLoading(false);
             toast.info("Verifique se os dados do cartão inserido estão corretos");
             return 0;
         }

         Iugu.createPaymentToken(cc, function(response) {
             if (response.errors) {
                 Sentry.captureException(response.errors);
                 console.log(response.errors)
                 setLoading(false);
                 //alert("Erro salvando cartão");
             } else {
                 props.sendTokenAndInvoice(response.id,parcels);
                 setLoading(false);
                 //alert("Token criado:" + response.id);
             }
         });
        /* eslint-enable */


    };

    const handleInputFocus = (evt) => {
        setState((prev) => ({ ...prev, focus: evt.target.name }));
    }

    const onSubmit = (e) => {
        console.log(e)
        if(parcels) {
            generateToken(e)
        } else {
            toast.info('Escolha as parcelas');
        }
    }

    const onError = (err) => {
        console.log(err)
    }

    useEffect(() => {
        configureIugu();
    },[])

    const options = [
        { value: '1', label: <div className={'w-100 d-flex justify-content-between'}><span>1x</span> <span><strong className={'text-success'}>R$</strong> {globalMaskReal(props?.price?.toString())}</span> </div> },
        { value: '2', label: <div className={'w-100 d-flex justify-content-between'}><span>2x</span> <span><strong className={'text-success'}>R$</strong> {globalMaskReal((props?.price/2)?.toString())}</span> </div> },
        { value: '3', label: <div className={'w-100 d-flex justify-content-between'}><span>3x</span> <span><strong className={'text-success'}>R$</strong> {globalMaskReal((props?.price/3)?.toString())}</span> </div> },
        { value: '4', label: <div className={'w-100 d-flex justify-content-between'}><span>4x</span> <span><strong className={'text-success'}>R$</strong> {globalMaskReal((props?.price/4)?.toString())}</span> </div> },
        { value: '5', label: <div className={'w-100 d-flex justify-content-between'}><span>5x</span> <span><strong className={'text-success'}>R$</strong> {globalMaskReal((props?.price/5)?.toString())}</span> </div> },
        { value: '6', label: <div className={'w-100 d-flex justify-content-between'}><span>6x</span> <span><strong className={'text-success'}>R$</strong> {globalMaskReal((props?.price/6)?.toString())}</span> </div> },
        { value: '7', label: <div className={'w-100 d-flex justify-content-between'}><span>7x</span> <span><strong className={'text-success'}>R$</strong> {globalMaskReal((props?.price/7)?.toString())}</span> </div> },
        { value: '8', label: <div className={'w-100 d-flex justify-content-between'}><span>8x</span> <span><strong className={'text-success'}>R$</strong> {globalMaskReal((props?.price/8)?.toString())}</span> </div> },
        { value: '9', label: <div className={'w-100 d-flex justify-content-between'}><span>9x</span> <span><strong className={'text-success'}>R$</strong> {globalMaskReal((props?.price/9)?.toString())}</span> </div> },
        { value: '10', label: <div className={'w-100 d-flex justify-content-between'}><span>10x</span> <span><strong className={'text-success'}>R$</strong> {globalMaskReal((props?.price/10)?.toString())}</span> </div> },
    ]

    return (
        <div>
            <div className={'mb-4'}>
                <Cards
                    locale={{valid: 'Validade'}}
                    placeholders={{name: 'nome titular'}}
                    number={watch().number}
                    expiry={watch().expiry}
                    cvc={watch().cvc}
                    name={watch().name}
                    focused={state.focus}
                />
            </div>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <div className="mb-3">
                    <label htmlFor="name" className={'text-gray-600 form-label'}>Nome impresso no cartão</label>
                    <input type="text"
                           name={'name'}
                           className={`form-control ${errors?.name?.message ? ' is-invalid' : ''}`}
                           placeholder={"Nome titular"}
                           id={'name'}
                           {...register("name")}
                           onFocus={handleInputFocus}

                    />
                    <ErrorMessage errors={errors} name={"name"}/>
                </div>
                <div className="mb-3">
                    <label htmlFor="number-card" className={'d-flex form-label align-items-end justify-content-between'}>
                        Número do cartão

                        <div>
                            <img src={visa} alt="" className="h-25px"/>
                            <img src={master} alt="" className="h-25px"/>
                            <img src={america} alt="" className="h-25px"/>
                        </div>
                    </label>

                    <Controller name='number'
                                control={control}
                                render={({field: {onChange, value}}) => (
                                    <NumberFormat className={`form-control ${errors?.number?.message ? ' is-invalid' : ''}`}
                                                  id={'number-card'}
                                                  name={'number'}
                                                  value={value}
                                                  onValueChange={(e) => onChange(e.value)}
                                                  placeholder="Número do cartão"
                                                  format={'#### #### #### ####'}
                                                  mask={' '}
                                                  onFocus={handleInputFocus}
                                    />
                                )}
                    />
                    <ErrorMessage errors={errors} name={"number"}/>

                </div>
                <div className={'row'}>
                    <div className={'col-7'}>
                        <div className="input-control">
                            <label htmlFor="expiry" className={'text-gray-600 form-label'}>Validade</label>

                            <Controller name='expiry'
                                        control={control}
                                        render={({field: {onChange, value}}) => (
                                            <NumberFormat
                                                className={`form-control ${errors?.expiry?.message ? ' is-invalid' : ''}`}
                                                id={'expiry'}
                                                name="expiry"
                                                format={'##/##'}
                                                value={value}
                                                onValueChange={(e) => onChange(e.value)}
                                                placeholder="Validade"
                                                mask={' '}
                                                onFocus={handleInputFocus}
                                            />
                                        )}
                            />
                            <ErrorMessage errors={errors} name={"expiry"}/>

                        </div>
                    </div>
                    <div className={'col-5'}>
                        <div>
                            <label htmlFor="cvc" className={'form-label'}>
                                CVV
                                <span className="ms-1"
                                      data-bs-toggle="tooltip"
                                      aria-label="Enter a card CVV code"
                                      data-bs-original-title="Enter a card CVV code"
                                      data-kt-initialized="1"
                                >
                                    <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                    </i>
                                </span>
                            </label>
                            <Controller name='cvc'
                                        control={control}
                                        render={({field: {onChange, value}}) => (
                                            <NumberFormat
                                                className={`form-control ${errors?.cvc?.message ? ' is-invalid' : ''}`}
                                                id={'cvc'}
                                                name="cvc"
                                                format={'###'}
                                                value={value}
                                                onValueChange={(e) => onChange(e.value)}
                                                placeholder="Código (CVC)"
                                                mask={' '}
                                                onFocus={handleInputFocus}
                                            />
                                        )}
                            />
                            <ErrorMessage errors={errors} name={"cvc"}/>
                        </div>
                    </div>
                </div>

                <div className={'mt-4'}>
                    <h5> Parcelamento</h5>
                    <span>Parcele em ate <strong>10x</strong> sem juros!</span>

                    <Select onChange={(e) => setParcels(e?.value)}
                            placeholder={"Escolha suas parcelas"}
                            menuPlacement={"top"}
                            className={'mt-2'}
                            onMenuOpen={() => console.log('a')} options={options}
                    />
                </div>

                {(loading || props.validating) ? <div/> : <button className={'btn btn-success w-100 mt-4 d-flex align-items-center justify-content-center'} type={'submit'}>
                    Finalizar
                    <FaArrowRight style={{marginLeft: 10}}/>
                    </button>}
            </form>

        </div>
    )
}

export default React.memo(PaymentComponent)
