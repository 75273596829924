import * as yup from "yup";
import {useAxios} from "../../helpers/Api";
import useLocalStorage from "../../helpers/storage";
import React, {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {toast} from "react-toastify";
import NumberFormat from "react-number-format";
import ErrorMessage from "../ErrorMessage";
import {Env} from "../../helpers/Env";
import {useUser} from "../../helpers/useUser";
import {FaTruckLoading} from "react-icons/fa";
import {FaTruckArrowRight} from "react-icons/fa6";
import {globalMaskReal} from "../../helpers/Functions";

export const AddressFormComponent = ({activeStep, cart, step, handleChangeStep, session, disabled, handleChangeActiveAccordion, updateFreight}) => {

    const [selectedOption, setSelectedOption] = useState(cart.delivery_type || 'PAC');

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    let addressSchema = yup.object().shape({
        address_cep: yup.string().required('Informe seu CEP').min(8, 'Insira um CEP válido'),
        address_street: yup.string().required('Informe sua rua'),
        address_district: yup.string().required('Informe seu bairro'),
        address_city_name: yup.string().required('Informe sua cidade'),
        address_uf: yup.string().required('Informe seu estado'),
        address_number: yup.string().required('Informe o número da residência'),
        address_complement: yup.string().nullable(),
    });
    const api = useAxios();
    const apiCep = useAxios({api_url: 'https://viacep.com.br', headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        }});
    const form = useLocalStorage("form");
    const [loading, setLoading] = useState(false);
    const user = useUser();

    const {
        register,
        getValues,
        handleSubmit,
        control,
        reset,
        setValue,
        watch,
        formState: {errors,}
    } = useForm({
        resolver: yupResolver(addressSchema),
        defaultValues: {
            address_cep: Env.user_data?.person?.address_cep,
            address_street: Env.user_data?.person?.address_street,
            address_district: Env.user_data?.person?.address_district,
            address_city_name: Env.user_data?.person?.address_city_name,
            address_uf: Env.user_data?.person?.address_uf,
            address_number: Env.user_data?.person?.address_number,
            address_complement: Env.user_data?.person?.address_complement,
        },
    });
    const checkCEP = async (cep) => {
        try {
            setLoading(true)
            let res = await apiCep.get('/ws/'+cep+'/json/');
            const data = res.data
            let updateAddress = {
                ...getValues(),
                address_street: data?.logradouro,
                address_district: data?.bairro,
                address_uf: data?.uf,
                address_city_name: data?.localidade
            }
            reset(updateAddress)
        } catch (e) {
            toast.error(e || 'Ocorreu um erro')
        } finally {
            setLoading(false)
        }
    }
    const onSubmit = async (data) => {
        if(!loading) {
            if(disabled) {
                handleChangeActiveAccordion("payment")
            } else {
                setLoading(true);

                let res = await user.addressDataSave({...data, delivery_type: selectedOption })
                if(res) {
                    handleChangeActiveAccordion("payment")
                    updateFreight({freight: res.freight, delivery_type: res.delivery_type});
                }
                setLoading(false);
            }
        }
    }

    const onError = (error) => {
        console.log(error)
    }


    return <>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className={''}>
                <div>
                    <label className={'form-label'} htmlFor="address_cep">
                        CEP
                    </label>
                    <div className="input-group ">
                        <div className="input-group-prepend">
                            <span className="input-group-text"
                                  id="basic-addon1">
                                <i className="bi bi-pin fs-1 text-dark"></i>
                            </span>
                        </div>
                        <Controller name='address_cep'
                                    control={control}
                                    render={({field: {onChange, value}}) => (
                                        <NumberFormat
                                            className={`form-control ${errors?.address_cep?.message ? ' is-invalid' : ''}`}
                                            id={"address_cep"}
                                            name={'address_cep'}
                                            value={value}
                                            onValueChange={(e) => {
                                                onChange(e.value)
                                                if (e.value.length === 8) {
                                                    checkCEP(e.value)
                                                }
                                            }}
                                            disabled={loading || disabled}
                                            placeholder={`xx.xxx-xxx`}
                                            format={'##.###-###'}
                                            mask="_"
                                        />
                                    )}
                        />
                    </div>
                    <ErrorMessage errors={errors}
                                  name="address_cep"
                    />
                </div>
                <div style={{display: getValues().address_uf? 'block' : 'none'}}>
                    <div className={'row'}>
                        <div className={'col-12 col-lg-9'}>
                            <div className={"mt-2"}>
                                <label htmlFor="address_street" className="form-label">
                                    Rua
                                </label>
                                <div className="input-group ">
                                    <div className="input-group-prepend">
                                    <span className="input-group-text"
                                          id="basic-addon1">
                                        <i className="bi bi-house fs-1 text-dark"></i>
                                    </span>
                                    </div>

                                    <input name="number"
                                           id={"address_street"}
                                           disabled={loading || disabled}
                                           className={`form-control ${errors?.address_street?.message ? ' is-invalid' : ''}`}
                                           placeholder="Rua"
                                           {...register("address_street")}
                                    />
                                </div>
                                <ErrorMessage errors={errors}
                                              name="address_street"
                                />
                            </div>
                        </div>

                        <div className={'col-12 col-lg-3'}>
                            <div className={'mt-2'}>
                                <label  className="form-label" htmlFor="address_number">
                                    Número
                                </label>
                                <div className="input-group ">
                                    <input name="number"
                                           id={"address_number"}
                                           type={"number"}
                                           disabled={disabled}
                                           className={`form-control ${errors?.address_number?.message ? ' is-invalid' : ''}`}
                                           placeholder="Nº"
                                           {...register("address_number")}
                                    />
                                </div>
                                <ErrorMessage errors={errors}
                                              name="address_number"
                                />
                            </div>
                        </div>

                    </div>
                    <div className={'row'}>
                        <div className={'col-12 col-lg-12'}>
                            <div className={'mt-2'}>
                                <label  className="form-label" htmlFor="address_district">Bairro</label>
                                <div className="input-group ">
                                    <div className="input-group-prepend">
                                    <span className="input-group-text"
                                          id="basic-addon1">
                                        <i className="bi bi-building fs-1 text-dark"></i>
                                    </span>
                                    </div>

                                    <input name="number"
                                           id={"address_district"}
                                           className={`form-control ${errors?.address_district?.message ? ' is-invalid' : ''}`}
                                           placeholder="Bairro"
                                           disabled={loading || disabled}
                                           {...register("address_district")}
                                    />
                                </div>
                                <ErrorMessage errors={errors}
                                              name="address_district"
                                />
                            </div>

                        </div>
                    </div>
                    <div className={'mt-2'}>
                        <label className={"form-label"} htmlFor="address_city_name">
                            Cidade
                        </label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon1">
                                <i className="bi bi-pin-map fs-1 text-dark"></i>
                            </span>
                            </div>

                            <input

                                id={"address_city_name"}
                                name="text"
                                disabled={disabled}
                                className={`form-control ${errors?.address_city_name?.message ? ' is-invalid' : ''}`}
                                placeholder="Cidade"
                                {...register("address_city_name")}
                            />
                        </div>
                        <ErrorMessage errors={errors}
                                      name="address_city_name"
                        />
                    </div>
                    <div className={'mt-2'}>
                        <label  className="form-label" htmlFor="address_uf">
                            Estado
                        </label>
                        <div className="input-group ">
                            <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">
                            <i className="bi bi-map fs-1 text-dark"></i>
                        </span>
                            </div>

                            <input
                                   name="number"
                                   className={`form-control ${errors?.address_city_name?.message ? ' is-invalid' : ''}`}
                                   placeholder="Estado"
                                   disabled={disabled}
                                   {...register("address_uf")}
                            />
                        </div>
                        <ErrorMessage errors={errors}
                                      name="address_city_name"
                        />
                    </div>
                    <div className={'mt-2'}>
                        <label className="form-label" htmlFor="address_uf">
                            Complemento
                        </label>
                        <div className="input-group">

                            <div className="input-group-prepend">
                        <span className="input-group-text"
                              id="basic-addon1">
                            <i className="bi bi-text-paragraph fs-1 text-dark"></i>
                        </span>
                            </div>

                            <input name="number"
                                   className={'form-control'}
                                   disabled={disabled}
                                   placeholder="Observação/Complemento"
                                   {...register("address_complement")}
                            />
                        </div>
                        <ErrorMessage errors={errors}
                                      name="address_complement"
                        />

                        <div>
                            <div className='p-3 bg-light rounded mt-4'>
                                <div className={'d-flex align-items-center mb-3'}><FaTruckArrowRight className={'text-primary'}/> <h5
                                    className={'mb-0 ml-1'}> FRETE </h5></div>
                                <div className={'d-flex flex-column'}>
                                    <label className={'d-flex align-items-center'}>
                                        <input
                                            type="radio"
                                            value="SEDEX"
                                            className={'mr-1'}
                                            checked={selectedOption === "SEDEX"}
                                            onChange={handleOptionChange}
                                        />
                                        <div className={'d-flex justify-content-between w-100'}>
                                <span style={{fontSize: 15}}>
                                    <strong>Entrega Expressa</strong> - 2 a 3 dias úteis
                                </span>
                                            <span>
                                    R$ {globalMaskReal(cart.freight_sedex_price)}
                                </span>
                                        </div>

                                    </label>
                                    <label className={'d-flex align-items-center'}>
                                        <input
                                            type="radio"
                                            value="PAC"
                                            className={'mr-1'}

                                            checked={selectedOption === "PAC"}
                                            onChange={handleOptionChange}
                                        />
                                        <div className={'d-flex justify-content-between w-100'}>

                                <span style={{fontSize: 15}}>
                                    <strong>Entrega Econômica</strong> - 6 a 7 dias úteis
                                </span>
                                            <span>
                                    R$ {globalMaskReal(cart.freight_price)}
                                </span>
                                        </div>
                                    </label>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>

            </div>
            <div className="d-flex justify-content-center">
                <button type={"submit"} className={'btn btn-success mt-3 w-100'}>
                    Continuar
                </button>
            </div>
        </form>
    </>
}
