import {useEffect, useState,} from 'react';
import axios from 'axios';
import {Env} from "./Env";
import {toast} from "react-toastify";
import useLocalStorage from "./storage";
import {CountCart} from "./Functions";
import {useAxios} from "./Api";

export const useCart = () => {
    let cartStorage = useLocalStorage("cart");
    let {updateCount} = CountCart()
    const api = useAxios();

    const initCart = () => {
        let cartNow = cartStorage.getStorageData();

        if(cartNow && cartNow.flora_cart_id) {
            getCartData(cartNow.flora_cart_id);
        } else {
            cartStorage.remove();
        }
    }

    const addToCart = async (product) => {
        if (Env.cart_data?.flora_cart_id) {
            await addCartToApi(product);
        } else {
            await createNewCart(product);
        }
        return true;
    }

    const createNewCart = async (product) => {
        try {
            let res = await api.post('/flora/add-cart', {
                flora_product_variation_id: product.flora_product_variation_id,
                flora_size_id: product.size.flora_size_id
            });
            //toast.success(product.name + ' adicionado ao carrinho de compras.');
            handleUpdateData(res.data.object)
        } catch (e) {
            console.log(e);
        }
    }

    const initiateCheckoutFlag = async (product) => {
        try {
            await api.post('/flora/initiate-cart/'+product.flora_cart_id, null);
        } catch (e) {
            console.log(e);
        }
    }

   const addCartToApi = async (product) => {
       try {
           let res = await api.put('/flora/add-item-cart/'+Env.cart_data.flora_cart_id, {
               flora_product_variation_id: product.flora_product_variation_id,
               flora_size_id: product.size.flora_size_id
           });
           //toast.success(product.name + ' adicionado ao carrinho de compras.');
           handleUpdateData(res.data.object)
       } catch (e) {
           console.log(e);
       }
   }

    const removeCartFromApi = async (product) => {
        try {
            console.log(product)
            let res = await api.del('/flora/remove-item-cart/'+Env.cart_data.flora_cart_id+'/'+product.flora_cart_item_id);
            handleUpdateData(res.data.object);
            return res.data.object;
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    const handleUpdateData = (data) => {
        cartStorage.createStorageData(data);
        if (data.items?.length > 0) {
            updateCount(data.items.length);
        } else {
            updateCount(0)
        }
        Env.cart_data = data;
    }

    const getCartData = async (id) => {
        try {
            let res = await api.get('/flora/get-cart/' + (id || Env.cart_data.flora_cart_id));
            handleUpdateData(res.data.object);
            return res.data.object;
        } catch (e) {
            cartStorage.remove();
            updateCount(0)
            Env.cart_data = null;
            return null;
        }
    }

    const removeCart = () => {
        cartStorage.remove();
        updateCount(0)
        Env.cart_data = null;
    }

    return { addToCart, initCart, initiateCheckoutFlag, removeCartFromApi, getCartData, removeCart};
};
