import React, {useEffect, useMemo, useRef, useState} from 'react';
import logo from './media/images/logo.png';
import {useHistory} from "react-router-dom";
import whatsWhite from "./media/whatsappWhite.png";
import {FaCartShopping, FaChildDress, FaPersonHalfDress} from "react-icons/fa6";
import {CartComponent} from "./components/CartComponent";
import localStorage from "./helpers/storage";
import {useQueryClient} from "@tanstack/react-query";
import {CountCart} from "./helpers/Functions";
import {LiaWhatsapp} from "react-icons/lia";
import {BiCart, BiLogoWhatsapp, BiMenu, BiMenuAltLeft, BiSearch, BiTime, BiWindowClose, BiX} from "react-icons/bi";
import {MdOutlineWhatsapp} from "react-icons/md";
import {FaCaretRight, FaRocket, FaRuler, FaSearch, FaTimes, FaWhatsapp} from "react-icons/fa";
import {GiDress} from "react-icons/gi";
import {other} from "./SiteData";
import {GoSearch} from "react-icons/go";
import {useAxios} from "./helpers/Api";


export const Header = () => {
    const history = useHistory();
    const [openCart, setOpenCart] = useState(false);
    const api = useAxios();
    const [categoriesList, setCategoriesList] = useState([]);

    const {cartItemCount} = CountCart()

    const BadgeCountCart = () => useMemo(() => {
        if (cartItemCount === 0) return <></> ;

        return (
            <div style={{position: "absolute", left: 10, bottom: 10, height: 19, width: 19, color:"white", display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'red', borderRadius: '50%', }}>
                {cartItemCount}
            </div>
        )
    }, [cartItemCount]);

    const getData = async () => {
        try {
            let res = await api.get('/categories/list');
            if(res.data.object.length > 0) {
               setCategoriesList(res.data.object);
            }
        } catch (e) {
            throw e
        }
    }

    const closeDrawer = () => {
        let mobileMenu = document.getElementById('mobilemenu');
        let mask = document.querySelector('.mask-overlay');

        mobileMenu.classList.remove('active');
        mask.remove()
    }
    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            <CartComponent open={openCart} handleOpen={(item) => setOpenCart(item)}/>
            <header className="header-area p-0 style-three bg-white active shadow-sm">
                <div style={{backgroundColor: 'black', color: 'white'}}
                     className={'w-100 d-flex justify-content-center py-1 align-items-center'}>
                    <span style={{fontSize: 14, fontWeight: 600}}>
                        FRETE GRÁTIS A PARTIR DE R$399
                    </span>
                </div>
                <div className="container-fluid pl-4 pr-4 custom-container menu-rel-container">
                    <div className="row bg-white justify-content-between">
                        <div className="col-sm-2">
                            <div className="logo">
                                <a onClick={() => history.push('/' + window.location.search)}>
                                    <img style={{objectFit: "contain"}} src={logo} alt=""/>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-9 col-xl-9 order-lg-2 order-xl-1 menu-container">
                            <div className="mainmenu style-two style-three active">
                                <ul id="navigation">

                                    <li><a
                                        style={{
                                            letterSpacing: 3,
                                            fontWeight: window.location.href.indexOf('/categorias') !== -1 ? 'bold' : '500'
                                        }}
                                        onClick={() => history.push('/categorias' + window.location.search)}>Buscar</a>
                                    </li>
                                    <li><a style={{
                                        padding: 10,
                                        background: '#eee2f1',
                                        color: 'black', letterSpacing: 3,
                                        fontWeight: window.location.href.indexOf('/lancamentos') !== -1 ? 'bold' : '500'
                                    }}
                                           onClick={() => history.push('/lancamentos/?order_by=new' + (window.location.search? ('&' + window.location.search.substring(1)) : ''))}>Lançamentos</a>
                                    </li>
                                    <li><a
                                        style={{
                                            letterSpacing: 3,
                                            fontWeight: window.location.href.indexOf('/personalizado') !== -1 ? 'bold' : '500'
                                        }}
                                        onClick={() => history.push('/personalizado' + window.location.search)}>Personalizado</a>
                                    </li>

                                    <li><a
                                        style={{
                                            letterSpacing: 3,
                                            fontWeight: window.location.href.indexOf('/pronta-entrega') !== -1 ? 'bold' : '500'
                                        }}
                                        onClick={() => history.push('/pronta-entrega?available=true' + (window.location.search? ('&' + window.location.search.substring(1)) : ''))}>Pronta Entrega</a>
                                    </li>

                                    <li><a style={{letterSpacing: 3,}} onClick={() => {
                                        window.gtag && window.gtag("event", "whats_click");
                                        window.open(window.innerWidth < 768 ? 'https://wa.me/5567991797649' : 'https://web.whatsapp.com/send?phone=5567991797649', '_blank').focus();
                                    }}>FALE COMIGO</a></li>

                                    <li className="out-link align-items-center ml-3 position-relative">
                                        <a style={{padding: 0, letterSpacing: 3, display: 'flex', alignItems: 'center'}}
                                           className={'btn bg-transparent'}
                                           onClick={() => {
                                               console.log("cliquei")
                                               setOpenCart(!openCart)
                                           }}

                                        >

                                            <i><FaCartShopping style={{fontSize: 18, marginRight: 10}}/></i>
                                            <BadgeCountCart/>
                                        </a>

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div style={{backgroundColor: 'black', color: 'white'}}
                 className={'w-100 d-flex py-1 justify-content-center align-items-center'}>
                    <span style={{fontSize: 12}}>
                        FRETE GRÁTIS A PARTIR DE R$399
                    </span>
            </div>

            <header className="mobile-header bg-transparent px-2" style={{zIndex: 1}}>
                <div className="container-fluid shadow custom-container bg-white p-3">
                    <div className="row">
                        {/* Mobile menu Opener
					============================================= */}
                        <div className="col-8 d-flex justify-content-start align-items-center">
                        <div className="logo pt-1 pr-2">
                                <a onClick={() => history.push('/' + window.location.search)}>
                                    <img style={{height: 30}} src={logo} alt=""/>
                                </a>
                            </div>
                        </div>
                        <div className="col-4 d-flex justify-content-end align-items-center gap-2" style={{gap: 16}}>
                            <div style={{position: "relative"}} onClick={() =>   history.push('/categorias' + window.location.search)}>
                                <BiSearch style={{fontSize: 24, color: '#3b1b46'}}/>
                            </div>
                            <div style={{position: "relative"}} onClick={() => setOpenCart(!openCart)}>
                                <BiCart style={{fontSize: 24, color: '#3b1b46'}}/>
                                <BadgeCountCart/>
                            </div>
                            <div className="accordion-wrapper">
                                <a href="#" className="mobile-open mt-0"><BiMenuAltLeft style={{fontSize: 24}}/></a>
                            </div>
                        </div>
                    </div>
                    {/* /.row end */}
                </div>
                {/* /.container end */}
            </header>

            <div className="accordion-wrapper">
                {/* Mobile Menu Navigation
				============================================= */}
                <div id="mobilemenu" style={{overflow: "hidden"}} className="accordion  pt-0">
                    <ul>
                        <div className={"d-flex justify-content-center align-items-center px-4 pt-4 pb-2"} >
                            <div className="logo pt-0 d-flex justify-content-center">
                                <img src={logo} alt="" style={{height: 30}}/>
                            </div>
                            <BiX className={'ml-auto text-dark'}  size={25} onClick={() => {
                                closeDrawer()
                            }}/>
                        </div>
                        <div className={'px-3 pb-3 pt-1 border-bottom d-flex align-items-center'}>

                            <div  onClick={() => {
                                closeDrawer();
                                history.push('/categorias' + window.location.search)
                            }}  style={{flex: 1, borderRadius: 20, borderColor: "gray"}} className={' border  d-flex align-items-center py-1 px-2'}>
                                <BiSearch size={25} className={'mr-2 text-dark'}
                                          onClick={() => history.push('/categorias' + window.location.search)}/>
                                <small style={{color: 'gray'}} > Buscar </small>
                            </div>

                            <BiCart className={'mr-2 ml-2 text-dark'} size={25} onClick={() => {
                                closeDrawer()
                                setOpenCart(true)
                            }}/>

                        </div>
                        {/*<li className="out-link"><a className={'d-flex align-items-center'}
                                                    style={{
                                                        letterSpacing: 3,
                                                        fontWeight: window.location.href.indexOf('/categorias') !== -1 ? 'bold' : '400'
                                                    }}
                                                    onClick={() => {
                                                        closeDrawer();
                                                        history.push('/categorias' + window.location.search)
                                                    }}>
                            <FaCaretRight className={'mr-2 text-light'}/>
                            Buscar</a></li>*/}

                        <li className="out-link"><a className={'d-flex align-items-center'}
                                                    style={{
                                                        letterSpacing: 3,
                                                        fontWeight: window.location.href.indexOf('/lancamentos') !== -1 ? 'bold' : '400'
                                                    }}
                                                    onClick={() => {
                                                        closeDrawer();
                                                        history.push('/lancamentos/?order_by=new' + (window.location.search ? ('&' + window.location.search.substring(1)) : ''))
                                                    }}>
                            <FaCaretRight className={'mr-2 text-light'}/>
                            Lançamentos</a></li>

                        <li className="out-link"><a className={'d-flex align-items-center'}
                                                    style={{
                                                        letterSpacing: 3,
                                                        fontWeight: window.location.href.indexOf('/personalizado') !== -1 ? 'bold' : '400'
                                                    }}
                                                    onClick={() => {
                                                        closeDrawer();
                                                        history.push('/personalizado' + window.location.search)
                                                    }}>
                            <FaCaretRight className={'mr-2 text-light'}/>
                            Personalizado</a></li>

                        <li className="out-link"><a className={'d-flex align-items-center'}
                                                    style={{
                                                        letterSpacing: 3,
                                                        fontWeight: window.location.href.indexOf('/pronta-entrega') !== -1 ? 'bold' : '400'
                                                    }}
                                                    onClick={() => {
                                                        closeDrawer();
                                                        history.push('/pronta-entrega?available=true' + (window.location.search ? ('&' + window.location.search.substring(1)) : ''))
                                                    }}>
                            <FaCaretRight className={'mr-2 text-light'}/>
                            Pronta Entrega</a>
                        </li>


                        {categoriesList.map((item, key) => <li className="out-link"><a
                            className={'d-flex align-items-center'}
                            style={{letterSpacing: 3,}}
                            onClick={() => {
                                closeDrawer()
                                history.push('/categorias/' + item.flora_product_category_id + window.location.search.substring(1))
                            }}>
                            <FaCaretRight className={'mr-2 text-light'}/>
                            {item.name}</a></li>)}

                        <li className="out-link" onClick={() => {
                            window.gtag && window.gtag("event", "whats_click");
                            window.open(window.innerWidth < 768 ? 'https://wa.me/5567991797649' : 'https://web.whatsapp.com/send?phone=5567991797649', '_blank').focus();
                        }}><a className={'d-flex align-items-center'}
                              onClick={() => history.push('/categorias' + window.location.search)}> <FaWhatsapp
                            className={'mr-2 text-success'}/> Fale Comigo pelo whatsApp</a></li>


                        <div className="row align-items-center mt-5">
                            <div style={{flex: 1, height: 1, backgroundColor: "#c6c5c5"}}/>
                            <div className="d-flex social-media-icon ml-5 mr-5">
                                <a href={other.instagram} target={"_blank"} className={'instagram small'}
                                   style={{backgroundColor: 'white'}}>
                                    <i className="fab fa-instagram" style={{fontSize: 16}}></i>
                                </a>
                                <a href={other.facebook} target={"_blank"} className="facebook small"
                                   style={{backgroundColor: 'white'}}>
                                    <i className="fab fa-facebook" style={{fontSize: '1.7rem'}}></i>
                                </a>
                            </div>
                            <div style={{flex: 1, height: 1, backgroundColor: "#c6c5c5"}}/>

                        </div>

                    </ul>
                </div>
            </div>
        </>

    )
}
