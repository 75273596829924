import React from "react";
export const TableDress = () => {

    return (
        <div className="table_component">
            <table>
                <thead>
                <tr>
                    <th>Idade</th>
                    <th>Comprimento total</th>
                    <th>Busto</th>
                    <th>Cintura</th>
                    <th>Comprimento da Manga</th>
                    <th>Largura do Punho</th>
                </tr>
                </thead>
                <tbody>

                <tr>
                    <td className={'font-weight-normal  td-header-mobile'}>3 meses</td>
                    <td><strong style={{fontWeight: 600}}>34,5cm</strong> <span
                        className={'d-md-none'}>Comprimento total</span></td>
                    <td><strong style={{fontWeight: 600}}>45cm</strong> <span className={'d-md-none'}>Busto</span></td>
                    <td><strong style={{fontWeight: 600}}>45cm</strong> <span className={'d-md-none'}>Cintura</span></td>
                    <td><strong style={{fontWeight: 600}}>22cm</strong> <span
                        className={'d-md-none'}>Comprimento da Manga</span></td>
                    <td><strong style={{fontWeight: 600}}>14cm</strong> <span
                        className={'d-md-none'}>Largura do Punho</span></td>
                </tr>
                <tr>
                    <td className={'font-weight-normal td-header-mobile'}>6 meses</td>
                    <td><strong style={{fontWeight: 600}}>37,5cm</strong> <span
                        className={'d-md-none'}>Comprimento total</span></td>
                    <td><strong style={{fontWeight: 600}}>47cm</strong> <span className={'d-md-none'}>Busto</span></td>
                    <td><strong style={{fontWeight: 600}}>47cm</strong> <span className={'d-md-none'}>Cintura</span></td>
                    <td><strong style={{fontWeight: 600}}>23cm</strong> <span
                        className={'d-md-none'}>Comprimento da Manga</span></td>
                    <td><strong style={{fontWeight: 600}}>14,5cm</strong> <span
                        className={'d-md-none'}>Largura do Punho</span></td>
                </tr>
                <tr>
                    <td className={'font-weight-normal td-header-mobile'}>9 meses</td>
                    <td><strong style={{fontWeight: 600}}>40,5cm</strong> <span
                        className={'d-md-none'}>Comprimento total</span></td>
                    <td><strong style={{fontWeight: 600}}>49cm</strong> <span className={'d-md-none'}>Busto</span></td>
                    <td><strong style={{fontWeight: 600}}>49cm</strong> <span className={'d-md-none'}>Cintura</span></td>
                    <td><strong style={{fontWeight: 600}}>24cm</strong> <span
                        className={'d-md-none'}>Comprimento da Manga</span></td>
                    <td><strong style={{fontWeight: 600}}>15cm</strong> <span
                        className={'d-md-none'}>Largura do Punho</span></td>
                </tr>
                <tr>
                    <td className={'font-weight-normal td-header-mobile'}>1 ano</td>
                    <td><strong style={{fontWeight: 600}}>44,5cm</strong> <span
                        className={'d-md-none'}>Comprimento total</span></td>
                    <td><strong style={{fontWeight: 600}}>51cm</strong> <span className={'d-md-none'}>Busto</span></td>
                    <td><strong style={{fontWeight: 600}}>51cm</strong> <span className={'d-md-none'}>Cintura</span></td>
                    <td><strong style={{fontWeight: 600}}>28cm</strong> <span
                        className={'d-md-none'}>Comprimento da Manga</span></td>
                    <td><strong style={{fontWeight: 600}}>15,5cm</strong> <span
                        className={'d-md-none'}>Largura do Punho</span></td>
                </tr>
                <tr>
                    <td className={'font-weight-normal td-header-mobile'}>2 anos</td>
                    <td><strong style={{fontWeight: 600}}>48cm</strong> <span
                        className={'d-md-none'}>Comprimento total</span></td>
                    <td><strong style={{fontWeight: 600}}>53cm</strong> <span className={'d-md-none'}>Busto</span></td>
                    <td><strong style={{fontWeight: 600}}>53cm</strong> <span className={'d-md-none'}>Cintura</span></td>
                    <td><strong style={{fontWeight: 600}}>30cm</strong> <span
                        className={'d-md-none'}>Comprimento da Manga</span></td>
                    <td><strong style={{fontWeight: 600}}>16cm</strong> <span
                        className={'d-md-none'}>Largura do Punho</span></td>
                </tr>
                <tr>
                    <td className={'font-weight-normal td-header-mobile'}>3 anos</td>
                    <td><strong style={{fontWeight: 600}}>52cm</strong> <span
                        className={'d-md-none'}>Comprimento total</span></td>
                    <td><strong style={{fontWeight: 600}}>55cm</strong> <span className={'d-md-none'}>Busto</span></td>
                    <td><strong style={{fontWeight: 600}}>55cm</strong> <span className={'d-md-none'}>Cintura</span></td>
                    <td><strong style={{fontWeight: 600}}>33cm</strong> <span
                        className={'d-md-none'}>Comprimento da Manga</span></td>
                    <td><strong style={{fontWeight: 600}}>16,5cm</strong> <span
                        className={'d-md-none'}>Largura do Punho</span></td>
                </tr>
                <tr>
                    <td className={'font-weight-normal td-header-mobile'}>4 anos</td>
                    <td><strong style={{fontWeight: 600}}>56cm</strong> <span
                        className={'d-md-none'}>Comprimento total</span></td>
                    <td><strong style={{fontWeight: 600}}>55cm</strong> <span className={'d-md-none'}>Busto</span></td>
                    <td><strong style={{fontWeight: 600}}>55cm</strong> <span className={'d-md-none'}>Cintura</span></td>
                    <td><strong style={{fontWeight: 600}}>36cm</strong> <span
                        className={'d-md-none'}>Comprimento da Manga</span></td>
                    <td><strong style={{fontWeight: 600}}>18cm</strong> <span
                        className={'d-md-none'}>Largura do Punho</span></td>
                </tr>
                <tr>
                    <td className={'font-weight-normal td-header-mobile'}>5 anos</td>
                    <td><strong style={{fontWeight: 600}}>59,5cm</strong> <span
                        className={'d-md-none'}>Comprimento total</span></td>
                    <td><strong style={{fontWeight: 600}}>57cm</strong> <span className={'d-md-none'}>Busto</span></td>
                    <td><strong style={{fontWeight: 600}}>57cm</strong> <span className={'d-md-none'}>Cintura</span></td>
                    <td><strong style={{fontWeight: 600}}>38cm</strong> <span
                        className={'d-md-none'}>Comprimento da Manga</span></td>
                    <td><strong style={{fontWeight: 600}}>18,5cm</strong> <span
                        className={'d-md-none'}>Largura do Punho</span></td>
                </tr>
                <tr>
                    <td className={'font-weight-normal td-header-mobile'}>6 anos</td>
                    <td><strong style={{fontWeight: 600}}>63,5cm</strong> <span
                        className={'d-md-none'}>Comprimento total</span></td>
                    <td><strong style={{fontWeight: 600}}>59cm</strong> <span className={'d-md-none'}>Busto</span></td>
                    <td><strong style={{fontWeight: 600}}>59cm</strong> <span className={'d-md-none'}>Cintura</span></td>
                    <td><strong style={{fontWeight: 600}}>41cm</strong> <span
                        className={'d-md-none'}>Comprimento da Manga</span></td>
                    <td><strong style={{fontWeight: 600}}>19cm</strong> <span
                        className={'d-md-none'}>Largura do Punho</span></td>
                </tr>
                <tr>
                    <td className={'font-weight-normal td-header-mobile'}>7 anos</td>
                    <td><strong style={{fontWeight: 600}}>67cm</strong> <span
                        className={'d-md-none'}>Comprimento total</span></td>
                    <td><strong style={{fontWeight: 600}}>61cm</strong> <span className={'d-md-none'}>Busto</span></td>
                    <td><strong style={{fontWeight: 600}}>61cm</strong> <span className={'d-md-none'}>Cintura</span></td>
                    <td><strong style={{fontWeight: 600}}>43cm</strong> <span
                        className={'d-md-none'}>Comprimento da Manga</span></td>
                    <td><strong style={{fontWeight: 600}}>19,5cm</strong> <span
                        className={'d-md-none'}>Largura do Punho</span></td>
                </tr>
                <tr>
                    <td className={'font-weight-normal td-header-mobile'}>8 anos</td>
                    <td><strong style={{fontWeight: 600}}>72cm</strong> <span
                        className={'d-md-none'}>Comprimento total</span></td>
                    <td><strong style={{fontWeight: 600}}>63cm</strong> <span className={'d-md-none'}>Busto</span></td>
                    <td><strong style={{fontWeight: 600}}>63cm</strong> <span className={'d-md-none'}>Cintura</span></td>
                    <td><strong style={{fontWeight: 600}}>45cm</strong> <span
                        className={'d-md-none'}>Comprimento da Manga</span></td>
                    <td><strong style={{fontWeight: 600}}>20cm</strong> <span
                        className={'d-md-none'}>Largura do Punho</span></td>
                </tr>
                </tbody>
            </table>
        </div>

    )
}
