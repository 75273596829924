import React, {useEffect, useState} from 'react'
import ProductsComponent from "../../Categories/Components/ProductsComponent";
import {CollectionProducts} from "../../../SiteData";
import branch from "../../../media/branch.png"
import {useAxios} from "../../../helpers/Api";
import ProductsComponentCarousel from "../../../components/ProductsComponentCarousel";
const MostSaledComponent = ({hideLabel, padding0}) => {
    const [productList, setProductList] = useState([]);
    const api = useAxios();

    const getDressList = async () => {

        try {
            let res = await api.get('/product/variations/list?is_primary=true');
            if (res.data.object.length > 0) {
                setProductList(res.data.object);
            }
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        getDressList();
    }, []);

    return (
        <div className={'most-saled'}>
            <div className={("container " +( padding0? 'p-0': ''))} >
                {!hideLabel && <>
                <h2 className={'text-center mb-4 font-weight-super-light '} style={{letterSpacing: 3,}}>MAIS VENDIDOS</h2>
                </>}
                <ProductsComponentCarousel data={productList.slice(0, 5)}/>
            </div>
        </div>
    )
}

export default MostSaledComponent
