import React from 'react'
import {maskReal, maskRealNu, maskRealToView} from "../../../helpers/Functions";
import bike from "../../../media/images/bike.png";
import {MdDeliveryDining, MdOutlineHail, MdToys} from "react-icons/md";
import {Link} from "react-router-dom";
import {IoMdFlower} from "react-icons/io";
import {FaChildDress, FaPersonHalfDress} from "react-icons/fa6";
import {GiButterflyFlower} from "react-icons/gi";
import {BsCart, BsCartPlus, BsFlower3} from "react-icons/bs";
import {CiDeliveryTruck} from "react-icons/ci";
import {BiSolidCartAdd} from "react-icons/bi";
import {CgShoppingCart} from "react-icons/cg";

const ProductsComponent = ({data, newCategory}) => {

    return (
        <div className={'categories p-0'} >
            <div className={'row justify-content-center'}>
                {data?.map((item, index) => (
                    <div className={' col-6 col-lg-4 mb-4 mb-md-4'} key={index}>
                       <Link to={`/produto/${item.flora_product_variation_id}`+window.location.search} style={{color:'inherit'}} className={'shadow-hover h-100 w-100'}>
                           <div className="card h-100  border-0">
                               <div className="card-body p-0 h-100">
                                   <div className={'d-flex flex-column h-100 product'}>
                                       <div className={'position-relative'}>
                                           {item.product_images.length > 0 &&
                                               <img src={item.product_images[0].image_s3_url} alt=""/>}
                                           {item.product.discount > 0 &&
                                               <div className={'d-flex position-absolute'} style={{top: 10, right: 10}}>
                                                   <div className={'p-2 bg-white shadow-sm rounded'}
                                                        style={{fontWeight: 'bolder'}}>
                                                       <span
                                                           className={'font-weight-bold text-danger'}>{item.product.discount}%</span><br/>
                                                       <span>OFF</span>
                                                   </div>
                                               </div>}

                                           <div className={'d-flex flex-wrap position-absolute'}
                                                style={{bottom: 10, left: 10}}>


                                               {item.product.product_bonus_main.map((item, index) =>
                                                   <div key={index} className={'px-2 mr-2'}
                                                        style={{
                                                            background: 'rgb(255 255 255 / 76%)',
                                                            boxShadow: '2px 1px 3px 1px #0000000f',
                                                            borderRadius: '5px',
                                                            fontWeight: 400,
                                                            color: 'black'
                                                        }}>
                                                       <small>{item.bonus_product.name} <strong>Grátis</strong></small>
                                                   </div>
                                               )}
                                           </div>
                                       </div>

                                       <div className={'d-flex align-items-center mt-2 px-1 flex-wrap'}>
                                           {item.product_variation_available_sizes.map((item, index) => <div
                                                   style={{position: "relative"}}
                                                   key={index}
                                                   className={`button-tag d-flex align-items-center mb-1`}>
                                                   <small> {item.size.name}</small>
                                               </div>
                                           )}
                                       </div>
                                       <div className={'px-3 pt-2 text-center'}>
                                           <h4 className={'limit-rows clamp-1'}>{item.product.name}
                                               <small>({item.name})</small></h4>
                                           <div className={'d-flex'}>
                                               <span style={{fontSize: 14, color: 'rgb(123, 123, 123)'}}
                                                     className={'limit-rows clamp-3 descip'}>{item.product.description}</span>
                                           </div>
                                       </div>
                                       <div className={'d-flex align-items-center flex-column justify-content-between mt-auto'}>
                                           <div className={'p-3 text-center'}>
                                               {item.product.discount > 0 &&
                                                   <p className="price_from ">R$ {maskRealToView(Number(item.product.price))}</p>
                                               }
                                               <div className={'d-flex align-items-center justify-content-center'}>
                                                   <p className={'price'}>
                                                       R$ {maskRealToView(item.product.price - ((item.product.discount / 100) * item.product.price))}
                                                   </p>
                                               </div>

                                               <span className={'sub-price'}>
                                                   em até 10x de <strong> R$ {maskRealToView(Number((item.product.price - ((item.product.discount / 100) * item.product.price)) / 10))} </strong> sem juros
                                               </span>

                                           </div>
                                           <div className={'d-flex align-items-center'}>
                                               <span className="btn btn-light d-flex align-items-center">
                                                   <span style={{letterSpacing: '2px'}}>COMPRAR</span>
                                               </span>
                                               <span
                                                   className="btn ml-2 btn-outline-success border-2 border d-flex align-items-center">
                                                   <BiSolidCartAdd size={20}/>
                                               </span>
                                           </div>


                                       </div>

                                       <span style={{
                                           fontSize: 13,
                                           textTransform: 'capitalize',
                                           fontWeight: 600,
                                           color: '#00a650',
                                           gap: 5
                                       }} className={'d-flex align-items-center px-3 pb-3'}>

                                   </span>
                                   </div>
                               </div>
                           </div>
                       </Link>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ProductsComponent
