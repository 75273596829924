import React from "react";
import {FaDotCircle} from "react-icons/fa";


export const PrivacyPage = () => {

    return (
        <div className={'my-20 text-dark'} style={{paddingTop: "180px", paddingBottom: "180px"}}>
            <div className="container">
                <h1>Termos de Uso</h1>

                <h2>Introdução</h2>
                <p>
                    Bem-vindo ao <strong>Flora Ateliê</strong>, uma plataforma dedicada à criação e venda de vestidos
                    de alta qualidade, tanto à pronta entrega quanto personalizados sob demanda. Ao acessar e utilizar
                    nosso site, você concorda em cumprir e estar vinculado a estes Termos de Uso. Estes termos foram
                    elaborados para garantir a proteção e clareza nas interações entre nossa empresa e nossos clientes,
                    garantindo assim uma experiência de compra segura e satisfatória.
                </p>
                <p>
                    Por favor, leia atentamente estes Termos de Uso antes de começar a utilizar o site. Se você não
                    concordar com qualquer parte destes termos, recomendamos que não utilize nosso site. Caso tenha
                    dúvidas ou precise de esclarecimentos, não hesite em entrar em contato conosco através dos canais de
                    comunicação fornecidos.
                </p>
                <p>
                    Nos reservamos o direito de alterar estes Termos de Uso periodicamente, e as alterações serão
                    publicadas em nosso site. É de sua responsabilidade verificar regularmente esta página para se
                    manter atualizado sobre quaisquer mudanças.
                </p>
                <p>
                    Obrigado por escolher <strong>Flora Ateliê</strong>. Estamos ansiosos para oferecer a você uma
                    experiência de compra excepcional.
                </p>

                <h2>Produto e Encomendas</h2>
                <p>
                    Olá, todas as nossas peças são produzidas após o seu pedido. Para minimizar trocas e devoluções,
                    procuramos abastecer nosso site com fotos que retratam fielmente os nossos produtos, juntamente com
                    descrições detalhadas. Caso haja alguma dúvida antes da compra, oferecemos atendimento personalizado
                    pelo nosso WhatsApp: (67) 9 9179-7649.
                </p>
                <p>
                    Antes de finalizar a compra, pedimos que verifique o nosso prazo de postagem, que é de no máximo 5
                    dias úteis após a confirmação do pagamento.
                </p>
                <p>
                    <strong>ATENÇÃO:</strong> Todos os fretes de troca ou devolução são por conta do cliente!
                </p>
                <h3>Trocas</h3>
                <ul>
                    <li>As trocas devem ser realizadas em até 7 dias corridos após o recebimento do pedido, e a
                        solicitação deve ser feita pelo nosso WhatsApp (67) 9 9179-7649 dentro deste prazo.
                    </li>
                    <li>Não aceitamos peças cuja solicitação de troca ou devolução não tenha sido realizada
                        previamente.
                    </li>
                    <li>As etiquetas devem estar afixadas nas peças.</li>
                    <li>Não realizamos trocas de peças em promoção, nem devoluções.</li>
                    <li>As peças não podem apresentar nenhum indício de uso ou alterações, tais como odores, manchas ou
                        ajustes de costura.
                    </li>
                    <li>O frete de reenvio das trocas é por conta do cliente.</li>
                    <li>Peças com qualquer tipo de personalização não estão sujeitas a trocas ou devoluções.</li>
                </ul>

                <h2>Preços e Pagamento</h2>
                <p>
                    Todos os preços exibidos em nosso site estão em Reais (BRL) e incluem os impostos aplicáveis. Nosso
                    objetivo é fornecer informações de preços precisas; no entanto, nos reservamos o direito de corrigir
                    quaisquer erros de precificação ou atualizações que possam ocorrer.
                </p>
                <p>
                    Para sua conveniência, aceitamos diversas formas de pagamento, incluindo:
                </p>
                <ul>
                    <li><strong>Pix:</strong> Pagamento instantâneo sem taxas adicionais.</li>
                    <li><strong>Cartão de Crédito:</strong> Aceitamos parcelamento.
                    </li>
                    <li><strong>Boleto Bancário:</strong> Pagamento à vista. O processamento pode levar alguns dias
                        úteis.
                    </li>
                </ul>
                <p>
                    Todas as transações são realizadas em um ambiente seguro para proteger suas informações pessoais.
                    Após a confirmação do pagamento, você receberá um e-mail confirmando seu pedido.
                </p>

                <h2>Entrega e Frete</h2>
                <p>
                    Nos comprometemos a processar e postar todos os pedidos em até 5 dias úteis após a confirmação do
                    pagamento. Nosso objetivo é garantir que os produtos cheguem até você de forma segura e no menor
                    tempo possível.
                </p>
                <p>
                    Os custos de frete variam de acordo com a localização de entrega e a opção de envio selecionada no
                    momento da compra. As opções de frete disponíveis serão apresentadas durante o processo de
                    finalização da compra.
                </p>
                <p>
                    Após o envio do pedido, você receberá um código de rastreamento para acompanhar a entrega através do
                    site da transportadora. Por favor, note que atrasos podem ocorrer devido a condições externas fora
                    de nosso controle, como feriados ou restrições locais.
                </p>
                <p>
                    Caso tenha alguma dúvida sobre o status do seu pedido ou necessite de assistência adicional,
                    recomendamos que entre em contato conosco através do nosso WhatsApp ou por e-mail.
                </p>

                <h2>Direitos de Propriedade Intelectual</h2>
                <p>
                    Todo o conteúdo, incluindo, mas não se limitando a, textos, gráficos, logotipos, ícones, imagens,
                    clipes de áudio, downloads digitais, compilações de dados e software, contidos ou disponibilizados
                    através do nosso site são de propriedade exclusiva de WebForward ou de seus fornecedores de
                    conteúdo e são protegidos por leis de direitos autorais locais e internacionais.
                </p>
                <p>
                    A compilação de todo o conteúdo deste site é de propriedade exclusiva de WebForward e é
                    protegida por leis de direitos autorais. Todas as marcas registradas, logos, marcas de serviço e
                    nomes comerciais exibidos neste site são de propriedade de WebForward ou de terceiros que
                    tenham autorizado seu uso.
                </p>
                <p>
                    É proibido o uso de qualquer conteúdo do site sem a expressa autorização por escrito de WebForward.
                </p>
            </div>
        </div>
    )
}
