import React from "react";
import {FaDotCircle} from "react-icons/fa";


export const AboutUsPage = () => {

    return (
        <div className={'my-20 text-dark'} style={{paddingTop: "180px", paddingBottom: "180px"}}>
            <div className="container">
                <h2>Sobre Nós</h2>
                <p>
                    Localizado em Campo Grande, MS, o Ateliê Flora é o coração onde todos os nossos vestidos são feitos
                    com o máximo cuidado e carinho. Nossa dedicação em selecionar tecidos e materiais é evidente em cada
                    peça, pois cada elemento é escolhido manualmente para garantir a melhor qualidade e conforto.
                </p>
                <p>
                    Nossos produtos são confeccionados com materiais de alta qualidade, garantindo uma durabilidade
                    excepcional e um toque refinado. Cada criação é projetada pensando no conforto e na elegância,
                    proporcionando um padrão superior em todas as nossas peças.
                </p>
                <p>
                    Para se conectar ainda mais com nosso universo, convidamos você a nos seguir no Instagram. Descubra
                    novidades, lançamentos e acompanhe o dia a dia do nosso ateliê, mergulhando nos bastidores e
                    inspirando-se com cada detalhe do nosso trabalho.
                </p>
                <p>
                    Se tiver dúvidas ou quiser saber mais sobre nossos produtos, não hesite em entrar em contato
                    conosco. Estamos à disposição para ajudar!
                </p>
            </div>
        </div>
    )
}
