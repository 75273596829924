import {useState,} from 'react';
import axios from 'axios';
import {Env} from "./Env";
import {toast} from "react-toastify";

export const useAxios = (axiosParams) => {
    const [loading, setLoading] = useState(!!axiosParams?.initialLoading);

    let config = {
        headers: axiosParams?.headers || Env.header,
        baseURL: axiosParams?.api_url || Env.api_url,
    };

    const genericCall = (url, method, data, query) => {
        config.url = url;
        config.data = data;
        config.method = method;
        config.params = query;

        let urlParams = window.location.search.substring(1);
        if(urlParams !== '') {
            urlParams = urlParams + '&uuid=' + Env.user_uuid;
        } else {
            urlParams = 'uuid=' + Env.user_uuid;
        }

        if(config.url.indexOf('?') !== -1) {
            config.url = config.url + '&' + urlParams;
        } else {
            config.url = config.url + '?' + urlParams;
        }

        return fetchData(config);
    }

    const get = (url, query) => {
        return genericCall(url,'GET', null,  query);
    }

    const post = (url, data) => {
        return genericCall(url,'POST', data);
    }

    const del = (url, data) => {
        return genericCall(url,'DELETE', data);
    }

    const put = (url, data) => {
        return genericCall(url,'PUT', data);
    }

    const handleError = (error) => {
        toast.update('1', { render: error, type: "error", autoClose: 3000, pauseOnHover: true,closeOnClick: true, isLoading: false });
        throw error
    }

    const handleSuccess = (show, message) => {
        //show && toast.update('1', { render: message || 'Tudo certo!', type: "success",closeOnClick: true, autoClose: 3000, isLoading: false });
        toast.done('1')
    }

    const handleFetching = (show) => {
        show && toast.loading("Carregando...",{toastId: '1'});
    }

    const fetchData = async (params) => {
        try {
            setLoading(true);
            handleFetching(params.method !== 'GET');
            let response = await axios.request(params);
            handleSuccess(params.method !== 'GET', response.data?.message || response.message);
            return response;
        } catch ( error ) {
            if (error?.response) {
                //  ERROR CODE 400 404 500 etc TRATAR VALIDATOR

                if(error?.response?.data?.validator) {
                    const validators = Object.keys(error.response.data?.validator);
                    if(validators.length > 0) {
                        return handleError(error?.response?.data.validator[validators[0]][0])
                    }
                    else {
                        return handleError(error?.response?.data?.message)
                    }
                } else {
                    return handleError('ops')
                }
            } else if (error?.request) {
                // The request was made but no response was received
                console.log(error?.request);
                return handleError(error?.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error?.message);
                return handleError(error?.message);
            }
        } finally {
            setLoading(false);
        }
    };

    return { loading, get, put, post, del };
};
