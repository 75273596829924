import React, {useEffect, useMemo, useState} from 'react';
import {Route, Switch, useHistory, withRouter} from "react-router-dom";
import {Header} from "./Header";
import HomePage from "./HomePage";
import ProductPage from "./ProductPage";
import ProductPageCustom from "./ProductPageCustom";
import {WhatsAppButton} from "./components/WhatsAppButton";
import {CategoriesPage} from "./pages/Categories/CategoriesPage";
import {Footer} from "./Footer";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-credit-cards-2/dist/lib/styles.scss"
import {TermsPage} from "./TermsPage";
import {useQuery} from "@tanstack/react-query";
import useLocalStorage from "./helpers/storage";
import FinishedOrderPage from "./pages/Finished/FinishedOrderPage";
import {PrivacyPage} from "./PrivacyPage";
import {AboutUsPage} from "./AboutUs";


const Routes = () => {
    const history = useHistory();
    let getCart = useLocalStorage("cart")?.getStorageData();

    const {} = useQuery({
        queryKey: ["count-cart"],
        initialData: () => getCart ? getCart.length : 0,
    })

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.gtag('config', 'G-8KPJ0VH66L', {'page_location': window.location.pathname});
            window.gtag('config', 'G-8KPJ0VH66L', {'page_path': window.location.pathname});
        }
    },[window.location.pathname]);

    return (
        <div className="site-content">
            <Header/>
            <ToastContainer />
            <main>
                <Switch>
                    <Route>
                        <Route exact path={'/'} component={HomePage}/>
                        <Route exact path={'/produto/:id'} component={ProductPage}/>
                        <Route exact path={'/colecao/:id'} component={HomePage}/>
                        <Route exact path={'/categorias/:category'} component={CategoriesPage}/>
                        <Route exact path={'/categorias'} component={CategoriesPage}/>
                        <Route exact path={'/lancamentos'} component={CategoriesPage}/>
                        <Route exact path={'/pronta-entrega'} component={CategoriesPage}/>
                        <Route exact path={'/pedido-concluido/:identifier'} component={FinishedOrderPage}/>
                        <Route exact path={'/sobre-mim'} component={HomePage}/>
                        <Route exact path={'/personalizado'} component={ProductPageCustom}/>
                        <Route exact path={'/trocas'} component={TermsPage}/>
                        <Route exact path={'/termos'} component={PrivacyPage}/>
                        <Route exact path={'/sobre'} component={AboutUsPage}/>
                    </Route>
                </Switch>
                <WhatsAppButton/>
                <Footer/>

            </main>
        </div>
    )
};

Routes.propTypes = {};

export default withRouter(Routes);
