
import {useHistory, useLocation, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import ProductsComponent from "./Components/ProductsComponent";
import {useAxios} from "../../helpers/Api";
import {HomePageData, other} from "../../SiteData";
import branch from "../../media/flower.png";
import {InstagramList} from "../../components/InstagramList";
import {Skeleton} from "../../components/Skeleton/Skeleton";
import {useQuery} from "@tanstack/react-query";
import {BiCart, BiFilter, BiFilterAlt, BiMenu, BiSearch, BiX} from "react-icons/bi";
import Drawer from "react-modern-drawer";
import {FaCaretRight, FaCreditCard, FaWhatsapp} from "react-icons/fa";
import logo from "../../media/images/logo.png";
import {PiNeedle, PiX} from "react-icons/pi";
import {MdDiscount} from "react-icons/md";
import {TbNeedle} from "react-icons/tb";

export const CategoriesPage = () => {
    const params = useParams();
    const [activeCategory, setActiveCategory] = useState(params?.category || "");
    const api = useAxios();
    const [activeCategoryItem, setActiveCategoryItem] = useState(null);
    const [orderBy, setOrderBy] = useState('popular');
    const [available,] = useState(params?.category || "");
    const [size, setSize] = useState("")
    const [discount, setDiscount] = useState("")
    const [filterDrawer, setFilterDrawer] = useState(false);
    const [orderDrawer, setOrderDrawer] = useState(false);
    const getData = async () => {
      try {
          let res = await api.get('/categories/list');
          if(res.data.object.length > 0) {
             return res.data.object
          } else return []
      } catch (e) {
        throw e
      }
    }

    const getSizes = async () => {
        try {
            let res = await api.get('/sizes/list');
            if(res.data.object.length > 0) {
                return res.data.object
            } else return []
        } catch (e) {
            throw e
        }
    }

    const getDressList = async () => {

        try {
            let res = await api.get('/product/variations/list?category_id='+activeCategory + '&order_by=' + orderBy + '&size=' + size + '&discount=' + discount+ '&available=' + available);
            if (res.data.object.length > 0) {
                return res.data.object
            } else {
                return []
            }
        } catch (e) {
            console.log(e);
        }
    }

    const {data: categories, isLoading: isLoadingCategories} = useQuery({
      queryKey:["categories"],
      queryFn:getData
    })

    const {data: sizes} = useQuery({
      queryKey:["sizes"],
      queryFn:getSizes
    })

    const {data: productList, isLoading: isLoadingProductList, isFetching} = useQuery({
        queryKey:["products", activeCategory, size, orderBy, discount],
        queryFn: getDressList
    })

    const sentAnalyticsEvent = (item) => {
        window.gtag && window.gtag("event", "search", {
            search_term: item
        });
    }

    useEffect(() => {
        if(params.category) {
            setActiveCategory(params.category)
        }
    }, [params]);

    useEffect(() => {
        if(activeCategory !== "") {

            categories?.forEach((item) => {
                if(item.flora_product_category_id == activeCategory) {
                    setActiveCategoryItem(item)
                }
            })
        } else {
            setActiveCategoryItem(null)
        }
        window.scrollToElement('body');
    }, [activeCategory,categories]);

    return (
        <div className={'h-100 categories'}>

            <Drawer width={150} direction={"left"} open={filterDrawer} onClose={() => setFilterDrawer(false)}>
                <div className={'p-2 d-flex flex-column justify-content-center align-items-center'}>
                    <h6 className={' text-center mb-4 mt-4 font-weight-bold'}
                        style={{letterSpacing: 5}}>CATEGORIAS</h6>

                    {categories?.map((item, key) => <div key={key} onClick={() => {
                        setFilterDrawer(false)
                        setActiveCategory(item.flora_product_category_id)
                    }} className="w-100 d-flex  py-2 justify-content-between align-items-center"
                                                         style={{borderTop: '1px solid gainsboro',}}>
                        <a style={{
                            letterSpacing: 3,
                            fontWeight: activeCategory == item.flora_product_category_id ? 'bold' : 'normal',
                            color: activeCategory == item.flora_product_category_id ? '#3b1b46' : '#3f3f3f'
                        }} className={'d-flex align-items-center justify-content-center text-uppercase'}>
                            {item.name}
                        </a>
                        <FaCaretRight className={'mr-2 text-dark'}/>
                    </div>)}


                    <h6 className={' text-center mb-4 mt-3   font-weight-bold'}
                        style={{letterSpacing: 5}}>TAMANHOS</h6>

                    {sizes?.map((item, key) => <div key={key} onClick={() => {
                        setFilterDrawer(false)
                        setSize(item.flora_size_id)
                    }} className="w-100 d-flex  py-2 justify-content-between align-items-center"
                                                    style={{borderTop: '1px solid gainsboro',}}>
                        <a style={{
                            letterSpacing: 3,
                            fontWeight: size == item.flora_size_id ? 'bold' : 'normal',
                            color: size == item.flora_size_id ? '#3b1b46' : '#3f3f3f'
                        }} className={'d-flex align-items-center justify-content-center text-uppercase'}>
                            {item.name}
                        </a>
                        <FaCaretRight className={'mr-2 text-dark'}/>
                    </div>)}

                    <div onClick={() => {
                        setFilterDrawer(false)
                        setSize('')
                    }} className="w-100 d-flex  py-2 justify-content-between align-items-center"
                         style={{borderTop: '1px solid gainsboro',}}>
                        <a style={{
                            letterSpacing: 3,
                            fontWeight: !size ? 'bold' : 'normal',
                            color: !size ? '#3b1b46' : '#3f3f3f'
                        }} className={'d-flex align-items-center justify-content-center text-uppercase'}>
                            TODOS
                        </a>
                        <FaCaretRight className={'mr-2 text-dark'}/>
                    </div>

                    <h6 className={' text-center mb-4 mt-3 font-weight-bold'}
                        style={{letterSpacing: 5}}>DESCONTO</h6>

                    <div onClick={() => {
                        setFilterDrawer(false)
                        setDiscount(true)
                    }} className="w-100 d-flex  py-2 justify-content-between align-items-center"
                         style={{borderTop: '1px solid gainsboro',}}>
                        <a style={{
                            letterSpacing: 3,
                            fontWeight: discount ? 'bold' : 'normal',
                            color: discount ? '#3b1b46' : '#3f3f3f'
                        }} className={'d-flex align-items-center justify-content-center text-uppercase'}>
                            VER PROMOÇÕES
                        </a>
                        <FaCaretRight className={'mr-2 text-dark'}/>
                    </div>

                    <div onClick={() => {
                        setFilterDrawer(false)
                        setDiscount('')
                    }} className="w-100 d-flex  py-2 justify-content-between align-items-center"
                         style={{borderTop: '1px solid gainsboro',}}>
                        <a style={{
                            letterSpacing: 3,
                            fontWeight: !discount ? 'bold' : 'normal',
                            color: !discount ? '#3b1b46' : '#3f3f3f'
                        }} className={'d-flex align-items-center justify-content-center text-uppercase'}>
                            TODOS
                        </a>
                        <FaCaretRight className={'mr-2 text-dark'}/>
                    </div>

                </div>
            </Drawer>

            <Drawer width={150} direction={"left"} open={orderDrawer} onClose={() => setOrderDrawer(false)}>
                <div className={'p-2 d-flex flex-column justify-content-center align-items-center'}>
                    <h6 className={' text-center mb-4 mt-4 font-weight-bold'}
                        style={{letterSpacing: 5}}>ORDENAÇÃO</h6>

                    <div onClick={() => {
                        setOrderDrawer(false)
                        setOrderBy('new')
                    }} className="w-100 d-flex  py-2 justify-content-between align-items-center"
                         style={{borderTop: '1px solid gainsboro',
                             fontWeight: orderBy === 'new'? 'bold' : 'normal',
                             color: orderBy === 'new' ? '#3b1b46' : '#3f3f3f'
                        }}>
                        <a style={{letterSpacing: 3,}}
                           className={'d-flex align-items-center justify-content-center text-uppercase'}>
                            MAIS RECENTE
                        </a>
                        <FaCaretRight className={'mr-2 text-dark'}/>
                    </div>
                    <div onClick={() => {
                        setOrderDrawer(false)
                        setOrderBy('popular')
                    }} className="w-100 d-flex  py-2 justify-content-between align-items-center"
                         style={{borderTop: '1px solid gainsboro',
                             fontWeight: orderBy !== 'new'? 'bold' : 'normal',
                             color: orderBy !== 'new' ? '#3b1b46' : '#3f3f3f'}}>
                        <a style={{letterSpacing: 3,}}
                           className={'d-flex align-items-center justify-content-center text-uppercase'}>
                            MAIS POPULAR
                        </a>
                        <FaCaretRight className={'mr-2 text-dark'}/>
                    </div>
                </div>
            </Drawer>

            <section>
                {window.innerWidth >= 768 ?
                    <div className="item align-items-center d-flex">
                        <img className={'w-100'} src={activeCategoryItem?.image_s3_url || HomePageData.default_banner}
                             alt=""/>
                    </div>
                    :
                    <div className="item align-items-center d-flex flex-column">
                        <img className={'w-100'} style={{marginTop: 65}}
                             src={activeCategoryItem?.image_s3_url || HomePageData.banner_mobile} alt=""/>
                    </div>
                }
            </section>
            <div className={'container p-0'}>
                <div className="row justify-content-center p-3 py-4 m-0">
                    <h5 className={'text-uppercase text-dark mb-0'}
                        style={{letterSpacing: 5}}>{activeCategoryItem?.name || 'Todos'}</h5>
                </div>

                <div className={'p-3 d-flex align-items-center'} style={{backgroundColor: 'black'}}>
                    <div onClick={() => {
                        setFilterDrawer(true);
                    }} style={{flex: 1}} className={'d-flex align-items-center justify-content-center'}>
                        <span> <BiMenu size={30} className={'text-white'}/> </span>
                        <h6 className={'text-white font-weight-light text-center mb-0 ml-2'}
                            style={{letterSpacing: 5}}>FILTRAR</h6>
                    </div>
                    <div onClick={() => {
                        setOrderDrawer(true)
                    }} style={{flex: 1}} className={'d-flex align-items-center justify-content-center'}>
                        <span> <BiFilter size={30} className={'text-white'}/> </span>
                        <h6 className={'text-white font-weight-light text-center mb-0 ml-2'}
                            style={{letterSpacing: 5}}>ORDENAR</h6>
                    </div>
                </div>
            </div>
            <div className="container h-100 mt-3">

                <small className={'text-uppercase'} style={{color: 'gray'}}>
                    HOME | CATEGORIAS | {activeCategoryItem?.name || 'Todos'}
                </small>
                {/*<div className="row">
                    <div className={'d-flex flex-column col-12 mb-5'}>
                        <div style={{height: 1, width: '100%', backgroundColor: "#5f5f5f"}}/>
                        {isLoadingCategories ?
                            <Skeleton.Categories/>
                            :
                            <div className={'d-flex flex-wrap justify-content-center'}
                                 style={{fontSize: 20, color: "black"}}>
                                <div onClick={() => setActiveCategory('')} className={'m-2 p-2 '}
                                     style={{cursor: "pointer", fontWeight: !(activeCategory) ? 'bold' : '500'}}>
                                    <span style={{color: !(activeCategory) ? 'black' : ''}}> Todos </span>
                                </div>
                                {categories.map((item, index) =>
                                    <div key={index} onClick={() => {
                                        setActiveCategory(item.flora_product_category_id);
                                        sentAnalyticsEvent(item.name)
                                    }}
                                         className={'m-2 p-2 d-flex align-items-center '}
                                         style={{
                                             cursor: "pointer",
                                             fontWeight: (activeCategory == item.flora_product_category_id) ? 'bold' : '500',
                                             color: (activeCategory === item.flora_product_category_id) ? 'black' : '',
                                         }}>
                                        <span> {item.name} </span>
                                    </div>
                                )}
                            </div>
                        }
                        <div style={{height: 1, width: '100%', backgroundColor: "#5f5f5f"}}/>
                    </div>
                </div>*/}

                {isLoadingProductList ?
                    <Skeleton.Products/> :
                    <>
                        {productList?.length === 0 ?
                            <div className={'py-5 text-center'}>
                                <h3>Nenhum resultado</h3>
                            </div> :
                            <div className="row mt-2">
                                <div className="col-12">
                                    {(isFetching && !isLoadingProductList) &&
                                        <div className={'text-right mb-3'}><small>Atualizando...</small></div>}
                                    <ProductsComponent data={productList}/>
                                </div>
                            </div>
                        }
                    </>
                }
            </div>

            <div className={'row m-5'}>

                <div className={'col-12 col-lg-4 d-flex align-items-center p-3 justify-content-center mb-3 mb-lg-0'}
                     style={{backgroundColor: '#fafafa'}}>
                    <TbNeedle className={'mr-1'} style={{color: '#3b1b46'}}/>
                    <span className={'text-uppercase'} style={{color: '#3b1b46'}}>Confeccionados a mão</span>
                </div>

                <div className={'col-12 col-lg-4 d-flex align-items-center p-3 justify-content-center mb-3 mb-lg-0'}
                     style={{backgroundColor: '#fafafa'}}>
                    <FaCreditCard className={'mr-1'} style={{color: '#3b1b46'}}/>
                    <span className={'text-uppercase'} style={{color: '#3b1b46'}}>Em até 10x sem juros</span>
                </div>

                <div className={'col-12 col-lg-4 d-flex align-items-center p-3 justify-content-center mb-3 mb-lg-0'}
                     style={{backgroundColor: '#fafafa'}}>
                    <MdDiscount className={'mr-1'} style={{color: '#3b1b46'}}/>
                    <span className={'text-uppercase'} style={{color: '#3b1b46'}}>5% OFF no PIX</span>
                </div>

            </div>

            <div style={{paddingTop: 60, paddingBottom: 60, backgroundColor: '#fafafa'}}
                 className="section-heading pb-10">
                <div className={'d-flex w-100 justify-content-center mb-4'}>
                    <img src={branch} style={{height: 50}}/>
                </div>
                <h3 className={'mb-0'} style={{paddingBottom: 30}}>Fique por dentro e nos siga no <span>Instagram</span>
                </h3>
                <div className="w-100 m-auto pl-3 pr-3" style={{maxWidth: 600, color: '#7b7b7b'}}>
                      <span style={{fontSize: 15}}>
                   Descubra as novidades, lançamentos e o dia a dia do nosso ateliê seguindo-nos no Instagram.
                          <br/><br/>
                          Acompanhe nossas criações, veja bastidores e inspire-se com cada detalhe do nosso trabalho.
                          <br/><br/>
                </span>
                </div>

            </div>

            <InstagramList/>

        </div>
    )
}
